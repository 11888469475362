
import { windownLoad } from './utils/Events';

import './Validation';
import { startAOS } from './effects/AnimationOnScroll';
import './utils/ScrollTo';

import { startScrollTrigger } from './effects/ScrollTrigger'
import Ripple from './effects/ButtonMaterialRipple'
import $ from 'cash-dom';
import { setupAutoGrow } from './forms/TextareaAutoSize';
import './slider'
$('html').removeClass('no-js');

Ripple.init();

windownLoad(() =>{
    startScrollTrigger();
    startAOS();

});

