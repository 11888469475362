
const format=require('format-number');
const { default: gsap } = require('gsap');
const numberFormatter = format({
    integerSeparator:' '
});


window.calcAmount = function(val){
    val = parseInt(val);
    if(val === 50){
        return 50000;
    }
    const side = val > 100? 2 : 1;
    if(side === 1){
        // Kod brokera Finea: PCPR34144
        $("[name=brokerCode]").val('PCPR34144');
        $("[name=crm]").val('finea');
        var factor = val/100;
        var amount = 500 + ((100000-500)*factor);
        console.log({factor, amount, val});
        amount = Math.round(amount/100)*100;
        return amount;
    }else{
        // Kod brokera eFaktor: PCPR43537
        $("[name=brokerCode]").val('PCPR43537');
        $("[name=crm]").val('efactor');
        var factor = (val-100)/100;
        var amount = 100000 + ((5000000-100000)*factor);
        console.log({factor, amount, val});
        amount = Math.round(amount/100000)*100000;
        // amount = 
        return amount;
    }

}

$('.section-slider').each(function(i, el) {

    const $rangeContainer = $('.range-slider', el);
    const $amountInput = $('input[name=amount]', el);
    const $html = $(document.documentElement);
    const $rangeSlider = $("#rangeSlider", el);
    const $tooltip = $('.range-tooltip', el);
    var obj = {
        value: 0
    };
    gsap.to(obj, 1, {
        value: 50, 
        delay: .5,
        // imageRendering:true,
        ease: Quint.easeOut,
        onUpdate: (args) => {
            // debugger;
            $rangeSlider.val(obj.value).trigger('input')
        }
    })
    
    $rangeSlider.on('input', e=>{
        // console.log(e.clientX, e.target.value);
        const thumbSize = 45;
        const range = e.target;
        const ratio = (range.value - range.min) / (range.max - range.min);
        console.log({ratio});
    
    
        const left = `calc(22px + ${ratio * (range.clientWidth - 45)}px)`;
        const amount = calcAmount(range.value);
        if(range.value > 100){ // efaktor
            $rangeContainer.add($html).addClass('above');
            $rangeContainer.add($html).removeClass('below');
    
            $('form input[name=brokerCode]').val(window.efaktor.brokerCode);
            $('form input[name=crm]').val(window.efaktor.crm);
    
        }else{ // finea
            $rangeContainer.add($html).removeClass('above');
            $rangeContainer.add($html).addClass('below');
            $('form input[name=brokerCode]').val(window.finea.brokerCode);
            $('form input[name=crm]').val(window.finea.crm);
        }
    
        $tooltip.show().css({
            left: left
        }).text(`${numberFormatter(amount)} pln`);
    
        $('form input[name=amount]').val(amount);
    });
    $rangeSlider.trigger('input');
});



$(window).on('resize', e => {
    $("#rangeSlider").trigger('input');
})
