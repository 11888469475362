import $ from 'cash-dom'
// Select all links with hashes
$('a[href*="#"],[data-scroll-to]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .on('click',function (event) {
        // On-page links

        // Figure out element to scroll to
        var st = $(this).data('scrollTo');
        if( this.hash ){
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        }

        if (st) {
             var target = $(st);
        }
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            window.scrollTo({
                top: target.offset().top - 60,
                behavior:'smooth',
            });
            
            
        }

    });