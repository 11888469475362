import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import fileSize from 'filesize';
export function Dropzone({ className = 'Dropzone', onChange }) {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDragEnter: (event) => {
      setDragging(true);
      setError(false);
    },
    onDragLeave: (event) => {
      setDragging(false);
      setError(false);
    },
    onDropAccepted: (acceptedFiles, event) => {
      setDragging(false);
      setError(false);
      var _files = files.concat(acceptedFiles);
      setFiles(_files);
    },
    onDropRejected: (event) => {
      setDragging(false);
      setError(true);
    }
  });

  function removeFile(file) {
    setFiles(files.filter(f => f !== file));
  }

  useEffect(() => {
    onChange(files);
  }, [files]);

  const filesList = files.map((file, i) => (
    <li className={`${className}__file`} key={i}>
      <svg className="icon">
        <use xlinkHref="#sprite-doc"></use>
      </svg>
      <div className="name">{file.name}</div>
      <div className="size">{fileSize(file.size)}</div>
      <div className="remove" onClick={e => { removeFile(file) }}>
        <svg className="icon">
          <use xlinkHref="#sprite-cross"></use>
        </svg></div>
    </li>
  ));



  return (
    <div className={className + ` ${dragging ? ' dragging drop-ripple ' : ''} `}>
      <div {...getRootProps({ className: `${className}__dropzone` })}>
        <input {...getInputProps()} />

        <div className="d-flex w-100">
          <div className="cell">

            <b >
              <svg style={{
                marginRight: '0.6em',
                position: 'relative',
                top: '-0.2em'
              }} className="icon">
                <use xlinkHref="#sprite-upload"></use>
              </svg>
              Załącz plik
            </b>
          </div>
          <div className="cell ms-auto">
            <span className="underline">Dodaj plik</span>
          </div>
        </div>


      </div>
      <div className={`${className}__list`}>
        <ul>{filesList}</ul>
      </div>
    </div>
  );
}