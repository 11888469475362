import '../scss/app.scss';
// or get all of the named exports for further usage
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

var tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-tooltip]'));
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  var t = new bootstrap.Tooltip(tooltipTriggerEl);
  tooltipTriggerEl.tooltip = t;
  return t;
});



import './main.js'