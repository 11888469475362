import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import React from 'react';
import ReactDOM from 'react-dom';
import {
    Dropzone
} from './forms/Dropzone.jsx';
import { Modal } from 'bootstrap';
const jqueryValidation = require('jquery-validation');

const thxModal = new Modal(document.getElementById('thxModal'));
window.thxModal = thxModal;

// polish letters added to jquery validation
jQuery.validator.addMethod('lettersOnly', function(value, element) {
    return this.optional(element) || /^[a-z A-ZąĄęĘżŻźŹćĆńŃŚśłŁóÓ\-]+$/i.test(value);
}, 'Dozwolone są tylko litery');

// polish letters added to jquery validation
jQuery.validator.addMethod('fullName', function(value, element) {
    return this.optional(element) || /^[a-z A-ZąĄęĘżŻźŹćĆńŃŚśłŁóÓ\-]+ [a-z A-ZąĄęĘżŻźŹćĆńŃŚśłŁóÓ\-]+$/i.test(value);
}, 'Podaj poprawnie "Imię i Nazwisko"');

// phone polish validatior
jQuery.validator.addMethod('phonePL', function(phone_number, element) {
    // phone_number = phone_number.replace(/\s+/g, '');
    return this.optional(element) || (phone_number.length === 9 );
}, 'Podaj poprawny "Numer telefonu"');

// kod pocztowy
jQuery.validator.addMethod("kodpocztowy", function(value, element) {
  return this.optional(element) || /^\d{2}-\d{3}$/.test(value);
}, "Wprowadź poprawny \"Kod pocztowy\"");


// NIP validatior
jQuery.validator.addMethod('nip', function(value, element) {

    function validatenip(nip) {
        var nip_bez_kresek = nip.replace(/-/g,"");
        var reg = /^[0-9]{10}$/;
        if(reg.test(nip_bez_kresek) == false) {
            return false;
        }
        else
        {
            var dig = (""+nip_bez_kresek).split("");
            var kontrola = (6*parseInt(dig[0]) + 5*parseInt(dig[1]) + 7*parseInt(dig[2]) + 2*parseInt(dig[3]) + 3*parseInt(dig[4]) + 4*parseInt(dig[5]) + 5*parseInt(dig[6]) + 6*parseInt(dig[7]) + 7*parseInt(dig[8]))%11;
            if(parseInt(dig[9])==kontrola)
            return true;
            else
            return false;
        }

    }

    function isInArray(value, array) {
      return array.indexOf(value) > -1;
    }

    var isChecksumOK = validatenip(value);
    var disallow = ["1111111111", "2222222222", "3333333333", "4444444444", "5555555555", "6666666666", "7777777777", "8888888888", "9999999999", "0000000000"];
    var isDisallowed = isInArray(value, disallow);

    var response = !isDisallowed && isChecksumOK;

	return this.optional(element) || response;

}, 'Proszę o podanie prawidłowego "numeru NIP".');

/**
 * @type {File[]} file
 */
var attachments = [];


function gup( name, url, nulled = true ) {
    var responseEmpty = (nulled == true? null : 'XXX')
    if (!url) url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? responseEmpty : results[1];
}

$('form input[name=comment]').val('medium='+gup('utm_medium', location.href, false)+' source='+gup('utm_source', location.href, false)+', campaign='+gup('utm_campaign', location.href, false)+'');



$('[data-check-all]').on('change', e=>{
    $('input[type=checkbox]').prop('checked', e.target.checked);
    // $('input[type=checkbox]').not(e.target).trigger('change');
    // $('input[type=checkbox]').not('[data-check-all]').trigger('change');
    // $('input[type=checkbox]').not('[data-check-all]').trigger('blur');
    $('input[type=checkbox]').not('[data-check-all]').trigger('keyup');
})

const swalPopup = Swal.mixin({
    title: "Ups!",
    // text: message,
    html: '',
    type: "error",
    buttonsStyling: false,
    confirmButtonClass: 'btn btn-primary'
});
// Validators in js/validators.js

$('form.section-contact').each((i, form) =>{

    $(form).validate({
        ignore: ':hidden:not(:checkbox)',
        focusInvalid: false,
        rules: {
            // name: {
            //     required: true
            // },
            email: {
                required: true,
                email: true
            },
            // message: {
            //     required: true,
            //     // nip: true
            // },
            phone: {
                required: true,
                phonePL: true,
                digits: true,
                // remote: {
                //     url: "js/check-phone.php",
                //     type: "post",
                //     data: {
                //       client_mobile: function() {
                //          return $("#phone_no").val();
                //       }
                //     }
                // }
            },
            nip: {
                required: true,
                digits: true,
                nip: true,
            },
            agree1: {
                required: true
            },
            // agreed_regulamin: {
            //     required: true
            // }
        },
        invalidHandler: function(event, validator) {
            // 'this' refers to the form
            // console.log(event, validator);
            var errors = validator.numberOfInvalids();
            var errorList = validator.errorList;
            // var message = "Wprowadź proszę poprawne dane<br><ul>";
            var message = "<ul>";
              // + this.numberOfInvalids()
    
            for (var i = 0; i < errorList.length; i++) {
                var field = errorList[i];
                message += '<li>' + field.message + '</li>';
            };
            message += '</ul>';
        //    swalPopup.fire({
        //         title: "Wprowadź poprawne dane",
        //         // text: message,
        //         html: message,
        //         type: "error",
        //         buttonsStyling: false,
        //         confirmButtonClass: 'btn btn-primary'
        //     });
    
        },
        /**
         * 
         * @param {Element} form 
         */
        submitHandler: function(form) {
            // acceptNavigation();
    
            var formData = new FormData(form);
    
            // attachments.forEach(a => {
            //     formData.append('attachments[]', a, a.name);
            // });
            
            $(form).find('[type=submit]').attr('disabled','disabled');
            
            fetch(form.getAttribute('action'),{
                body: formData,
                method: 'post'
            }).then(response => {
                console.log({ response });
                if (response.ok) {
                    // thxModal.show();
                    form.reset();
                    // renderDropzone();
                    swalPopup.fire({
                        title: "Dziękujemy!",
                        html: 'Wiadomosć została wysłana',
                        type: "success",
                    });

                    // gtag('event', 'conversion', {});
                    dataLayer.push({
                        'event': 'conversion'
                    });
                } else {
                    
                    swalPopup.fire({
                        title: "Ups!",
                        html: 'Coś poszło nie tak podczas wysyłania wiadomości',
                        type: "error",
                    });
                }
                
                
            }).catch(err => {
                console.error({ err });
                if (err['message']) {
                    
                    swalPopup.fire({
                        title: "Ups!",
                        html: err['message'],
                        type: "error",
                    });
                } else {
                    swalPopup.fire({
                        title: "Ups!",
                        html: err,
                        type: "error",
                    });
                    
                }
            }).finally(() => {
                
                $(window).trigger('resize');
                $(window).trigger('scroll');
                
                $(form).find('[type=submit]').removeAttr('disabled');
                
            });
        
    
        },
        messages: {
            name: {
                required: "Pole wymagane!",
            },
            nip: {
                required: "Pole wymagane!",
            },
            message: {
                required: "Pole wymagane!",
            },
            email: {
                required: "Pole wymagane!",
                email: "Podaj poprawny \"Email\"",
            },
            phone: {
                required: "Pole wymagane!",
                phonePL: "Numer niepoprawny!",
                digits: "Dozwolone jedynie cyfry",
            },
    
    
            agree1: {
                required: "Wyraź zgodę na przetwarzanie danych osobowych"
            },
            // agreed_regulamin: {
            //     required: "Zapoznaj się z regulaminem"
            // }
        },
        highlight: function(element) {
            $(element).addClass('error');
            // $(element).next('span').addClass('error-checkbox');
        },
        unhighlight: function(element) {
            $(element).removeClass('error');
            // $(element).next('span').removeClass('error-checkbox');
        },
        errorPlacement: function(error, element) {
            // debugger;
            if(element.is(':checkbox')){
                // debugger
                $(error).appendTo($(element).closest('.form-check'));
            }else{
                $(error).appendTo(element.parent());
    
            }
        }
    
    });
});