
import $ from 'cash-dom';

/**
 * Setup auto grow
 * @param {Element} el 
 */
export function setupAutoGrow(el) {
    console.log(el);
    $(el).on('input', function() {
        this.style.overflow = 'hidden';
        // this.style.height = 0;
        
        this.style.height = this.scrollHeight + 'px';
        // console.log(el);
    });
}
